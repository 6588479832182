<template>
  <div class="shop-top-header" :style="setCssVar">
    <nav class="nav-box">
      <div class="header-menu">
        <Dropdown @on-click="changeLanguage" class="mr15 languages">
          <div class="lang-text">
            <img :src="flagPath" width="24" height="16" class="cursor" alt="">
            <div class="ml8">{{ selectLang }}</div>
          </div>
          <template #list>
            <Dropdown-menu>
              <Dropdown-item v-for="(item, index) in languageList" :name="item.code" :key="index">
                <div class="language_item_style">
                  <img :src="item.iconPath" width="24" height="16" class="cursor mr10" alt="">
                  <span class="font-size-12">{{ item.title }}</span>
                  <Icon class="ml8" type="md-checkmark" size="18" color="#FD5425" v-if="flagPath == item.iconPath"/>
                </div>
              </Dropdown-item>
            </Dropdown-menu>
          </template>
        </Dropdown>
        <div class="right-box">
          <span class="header-txt welcome">{{ welcomeText }}</span>
          <template v-if="userInfo">
            <span class="header-txt selectTxt">{{ $t('key1000071') }}{{ $store.state.erpConfig.merchant.merchantId }}</span>
            <span class="header-txt pl10" @click="logout">{{ $t('key1000072') }}</span>
          </template>
          <template v-else>
            <a class="header-txt" :href="loginHref">{{ $t('key1000073') }}</a>
            <span class="line_item">|</span>
            <a class="header-txt" :href="registerHref" target="_blank">{{ $t('key1000074') }}</a>
          </template>
          <span class="line_item">|</span>
          <a class="header-txt" :href="newsListUrl" target="_blank">{{ $t('key1000075') }}</a>
          <template v-if="!isAlibabaDistributor">
            <span class="line_item">|</span>
            <span class="header-txt" @click="$refs.gettingStarted.open()">{{ $t('key1000076') }}</span>
          </template>
          <span class="line_item">|</span>
          <a class="header-txt" :href="basicInformationUrl" target="_blank">{{ $t('key1000077') }}</a>
          <span class="line_item">|</span>
          <a class="header-txt" :href="commodityManageUrl" target="_blank">{{ $t('key1000078') }}</a>
          <span class="line_item">|</span>
          <a class="header-txt" :href="myOrderUrl" target="_blank">{{ $t('key1000079') }}</a>
          <template v-if="!isAlibabaDistributor">
            <span class="line_item">|</span>
            <a :href="helpCenterUrl" class="header-txt" target="_blank">{{ $t('key1000036') }}</a>
          </template>
        </div>
      </div>
    </nav>
    <gettingStarted ref="gettingStarted"></gettingStarted>
  </div>
</template>

<script>
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';
import gettingStarted from '@/components/common/gettingStarted';
import {getToken, removeToken} from '@/utils/cookie';
import {getStoreDomainName} from '@/utils/user';
import {getHrefPath, isAlibabaDistributor, commonSessionStorage} from "@/utils/common";

export default {
  name: 'headTop',
  mixins: [Mixin],
  data() {
    return {
      selectLang: commonSessionStorage.getItem('selectLang') || '',
      flagPath: commonSessionStorage.getItem('flagPath') || '',
    };
  },
  created() {
    let v = this;
    setTimeout(() => {
      let code = localStorage.getItem('setLanguages') || v.$store.state.setLanguages;
      if (code) {
        v.systemDefaultLanguage(code);
      }
    }, 800);
  },
  methods: {
    // 切换语种
    changeLanguage(lang) {
      let v = this;
      v.$store.commit('setLanguages', lang);
      localStorage.setItem('setLanguages', lang);
      if (Object.keys(v.userInfo).length > 0) {
        v.axios.put(api.put_erpCommonSettingParam_cutoverLang + lang).then(response => {
          if (response.data.code === 0) {
            v.languageList.map((item) => {
              if (item.code === lang) {
                v.flagPath = item.iconPath;
                v.selectLang = item.title;
                localStorage.setItem('setLanguages', item.code);
                localStorage.setItem('lang', item.name);
                commonSessionStorage.setItem('flagPath', item.iconPath);
                commonSessionStorage.setItem('selectLang', item.title);
                handleStoreDomainName(item.name)
              }
            });
          }
        });
      } else {
        if (v.languageList.length > 0) {
          v.languageList.map((ele) => {
            if (ele.code === lang) {
              localStorage.setItem('lang', ele.name);
              handleStoreDomainName(ele.name)
            }
          })
        }
      }

      // 判断是否在店铺域名下
      function handleStoreDomainName(lang) {
        let talg = getStoreDomainName();
        if (talg) {
          let origin = window.location.origin;
          window.location.href = origin + `?lang=${lang}`;
          setTimeout(() => {
            window.location.reload();
          }, 1200);
        } else {
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      }
    },
    // 退出登录
    logout() {
      let v = this;
      v.axios.get(api.get_logout + getToken('signOut')).then(response => {
        if (response.data.code === 0) {
          removeToken().then(() => {
            setTimeout(() => {
              v.$store.commit('erpConfig', {});
              location.reload();
              // 在商城退出不需要重定向到登录页面
              // let url = deleteTicket(window.location.href);
              // window.location.href = response.data.others.logOutUrl + encodeURIComponent(url);
            }, 1500);
          });
        }
      });
    },
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    },
    // 登录
    loginHref() {
      let url = '/login.html#/login' + '?u=' + window.location.href;
      let service = '/yms-shopping-service';
      return getHrefPath(url, service);
    },
    // 免费注册
    registerHref() {
      let url = '/login.html#/register';
      let service = '/yms-shopping-service';
      return getHrefPath(url, service);
    },
    // 帮助中心
    helpCenterUrl() {
      let url = '/index.html#/help';
      let service = '/yms-shopping-service';
      return getHrefPath(url, service);
    },
    // 消息
    newsListUrl() {
      let url = '/distributor.html#/newsList';
      let service = '/yms-distributor-service';
      return getHrefPath(url, service);
    },
    // 个人中心
    basicInformationUrl() {
      let url = '/distributor.html#/commodityList';
      let service = '/yms-distributor-service';
      return getHrefPath(url, service);
    },
    // 我的收藏
    commodityManageUrl() {
      let url = '/distributor.html#/commodityList';
      let service = '/yms-distributor-service';
      return getHrefPath(url, service);
    },
    // 我的收藏
    myOrderUrl() {
      let url = '/distributor.html#/platformOrder';
      let service = '/yms-distributor-service';
      return getHrefPath(url, service);
    },
    welcomeText() {
      let text = '';
      if (isAlibabaDistributor()) {
        text = alias1bddc4d174174d47a6dfb0437dace856.t('key1000080');
      } else {
        text = alias1bddc4d174174d47a6dfb0437dace856.t('key1000081');
      }
      return text;
    },
    isAlibabaDistributor() {
      return isAlibabaDistributor()
    },
    setCssVar() {
      return {
        '--subject-color': this.isAlibabaDistributor ? '#2D8CF0' : '#FD5425'
      };
    }
  },
  components: {
    gettingStarted
  }
};
</script>

<style lang="less" scoped>
@import "src/assets/styles/shopping/common";

.shop-top-header {
  width: 100%;
  background: #FFFFFF;
  border-bottom: 1px solid #D7D7D7;

  .top_banner_style {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #FBDBE0;
    cursor: pointer;
  }

  .nav-box {
    display: flex;
    background-color: #F2F2F2;
    color: #333333;

    .header-menu {
      width: 1200px;
      margin: 0 auto;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .right-box {
        display: flex;
        justify-content: flex-end;
      }

      .welcome {
        padding-right: 5px;
      }

      .header-txt {
        cursor: pointer;
        display: inline-block;
        font-size: 12px;
        font-family: PingFang SC;
        color: #777777;
        display: flex;
        align-items: center;

        .user-icon {
          margin-right: 5px;
          font-weight: initial;
          color: #777777;
        }

        &:hover {
          color: var(--subject-color);

          .user-icon {
            color: var(--subject-color);
          }
        }
      }

      .selectTxt {
        color: var(--subject-color);
      }

      .line_item {
        color: #c3c1c1;
        margin: 0 10px;
      }
    }
  }

  .header-conter {
    width: 1200px;
    margin: 0 auto;
    position: relative;
  }
}

.header-bottom {
  border-bottom: 2px solid var(--subject-color);
}

.lang-text {
  display: flex;
  width: 150px;
  line-height: 32px;
  color: #777777;
  cursor: pointer;
  align-items: center;
}

.languages {
  /deep/ .ivu-dropdown-item {
    padding: 0 8px 0 8px;

    .language_item_style {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      span {
        display: inline-block;
      }
    }
  }
}
</style>
